import React from 'react'
import styled from 'styled-components';

export default function SamplesPage(props) {
  const samples = props.data.allContentfulWorkSample.edges;
  return (
    <PageWrapper>WORK</PageWrapper>
  );
}

export const samplePageQuery = graphql`
  query SampleQuery {
    allContentfulWorkSample {
    edges {
      node {
        client
        description
        date
        url
        title
      }
    }
  }
}
`

const PageWrapper = styled.div`
`